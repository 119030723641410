import React from 'react';

import { Game } from './components/Game';


export function App() {
  return <div>
    <Game />
  </div>;
}
