import 'typeface-roboto';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';

import { App } from './App';
import { store } from './store';


const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.Fragment>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </React.Fragment>
);


